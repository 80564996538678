<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <material-card
        :title="$t('tractAnalysis')"
        :text="subtitleText"
        :elevation="0"
        full-width>
          <v-tabs v-model="currentTab">
            <v-tab>
              <v-icon small class="mr-2">mdi-truck</v-icon>{{$t('deliveredCosts')}}
            </v-tab>
            <v-tab>
              <v-icon small class="mr-1">mdi-currency-usd</v-icon>{{$t('productCosts')}}
            </v-tab>
            <v-tab>
              <v-icon small class="mr-1">mdi-forest</v-icon>{{$t('standingTimber')}}
            </v-tab>
            <v-tab>
              <v-icon small class="mr-1">mdi-chart-arc</v-icon>{{$t('committed')}}
            </v-tab>
            <v-tab>
              <v-icon small class="mr-1">mdi-pine-tree-variant</v-icon>{{$t('products')}}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="currentTab" touchless>
            <v-tab-item>
              <DeliveredLogCosts/>
            </v-tab-item>
            <v-tab-item>
              <ProductCosts @tract-category-selected="productCostCategorySelected"/>
            </v-tab-item>
            <v-tab-item>
              <StandingTimber/>
            </v-tab-item>
            <v-tab-item>
              <CommittedTimber/>
            </v-tab-item>
            <v-tab-item>
              <TimberPortfolio/>
            </v-tab-item>
          </v-tabs-items>

        </material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'TractAnalysis',

  components: {
    DeliveredLogCosts: () => import('@/components/tract/tract-analysis/DeliveredLogCosts.vue'),
    ProductCosts: () => import('@/components/tract/tract-analysis/ProductCosts.vue'),
    StandingTimber: () => import('@/components/tract/tract-analysis/StandingTimber.vue'),
    CommittedTimber: () => import('@/components/tract/tract-analysis/CommittedTimber.vue'),
    TimberPortfolio: () => import('@/components/tract/tract-analysis/TimberPortfolio.vue')
  },

  data: () => ({
    loading: false,
    currentTab: 0,
    productCostCategory: 0
  }),

  computed: {
    subtitleText () {
      switch (this.currentTab) {
        case 0:
          return this.$t('tractAnalysisSubtitle')
        case 1:
          return this.productCostCategory === 0
            ? this.$t('stumpageProductCostsByTractSubtitle')
            : this.$t('deliveredProductCostsByTractSubtitle')
        case 2:
          return this.$t('standingTimberByTractSubtitle')
        case 3:
          return this.$t('committedByTractSubtitle')
        case 4:
          return this.$t('timberPortfolioSubtitle')
      }
      return ''
    }
  },

  methods: {
    productCostCategorySelected (category) {
      this.productCostCategory = category
    }
  }
}
</script>
